import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Odometer from 'react-odometerjs';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { formatIsoDate } from 'utils/dateFormatter';
import { getHolidayIconName } from 'DayPlan/utils/getHolidayIconName';
import withGTM from 'view/GoogleTagManager/withGTM';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import { CSSTransition } from 'react-transition-group';
import dinner from 'assets/images/dayplan/dinner.svg';
import chef from 'assets/images/dayplan/chef.svg';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import Footer from 'Layout/Footer';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';

class DayPlan extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        kcal: PropTypes.number.isRequired,
        phase: PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        trackEvent: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        dayPlanForTomorrow: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        }),

        showMacros: PropTypes.bool.isRequired,
        renderMealLinks: PropTypes.func.isRequired,
        fastingStatistics: PropTypes.shape({
            dietsCount: PropTypes.number,
            currentDietLength: PropTypes.number,
            currentDietProgress: PropTypes.number,
        }).isRequired,
        showModal: PropTypes.bool.isRequired,
        renderMealDetails: PropTypes.func.isRequired,

        showModalWithReplacements: PropTypes.bool.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        renderChangeDay: PropTypes.func.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
        showModalWithDayReplacements: PropTypes.bool.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        dietId: PropTypes.number.isRequired,
        isVegetarian: PropTypes.bool.isRequired,
        isPescoVegetarian: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        dayPlanForTomorrow: undefined,
    };

    state = {
        odometer: 0,
    };

    componentDidMount() {
        this.setState({ odometer: this.props.kcal });
        this.trackView();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.kcal !== this.props.kcal) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.kcal,
            });
        }

        if (formatIsoDate(prevProps.date) !== formatIsoDate(this.props.date)) {
            this.trackView();
        }
    }

    trackView() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );

        const dayDelta = Math.floor(
            (this.props.date - today) / 1000 / 60 / 60 / 24
        );

        const label = dayDelta > 0 ? `+${dayDelta}` : dayDelta;
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'view', label);
    }

    renderGoalAlert() {
        if (this.props.goal) {
            if (
                this.props.goal.lostBecauseOfLoseWeight ||
                this.props.goal.lostBecauseOfPutOnWeight
            ) {
                return (
                    <div className="alert-new danger" data-test="goal-lost">
                        <h4>{this.props.t('info-pages/goal-lost/title')}</h4>
                        <p>{this.props.t('info-pages/goal-lost/content')}</p>
                        <Button
                            color="primary"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-lost/button')}
                        </Button>
                    </div>
                );
            }

            if (
                this.props.goal.reachedBecauseOfLoseWeight ||
                this.props.goal.reachedBecauseOfPutOnWeight
            ) {
                return (
                    <div className="alert-new success" data-test="goal-reached">
                        <h4>{this.props.t('info-pages/goal-reached/title')}</h4>
                        <p>{this.props.t('info-pages/goal-reached/content')}</p>
                        <Button
                            color="secondary"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-reached/button')}
                        </Button>
                    </div>
                );
            }
        }

        return '';
    }

    renderMeasurementAlert() {
        if (this.props.isTimeToNagForCurrentMeasurement) {
            return (
                <div className="alert-new info">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('alert/periodic-measurements'),
                        }}
                    />
                    <Button
                        color="primary"
                        tag={Link}
                        to={{
                            pathname: PATH_MEASUREMENT_ADD,
                            state: {
                                from: {
                                    pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                        this.props.date
                                    )}`,
                                },
                            },
                        }}
                        className="no-arrow"
                    >
                        {this.props.t(
                            'info-pages/current-weight-needed/button'
                        )}
                    </Button>
                </div>
            );
        }

        return '';
    }

    renderHolidayAlert() {
        const isThisHoliday = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(this.props.date)
        );
        if (isThisHoliday && !this.props.isHolidayMenu) {
            return (
                <div className="alert-new info">
                    {isThisHoliday === 'chef' ? (
                        <>
                            <p className="mb-2">
                                <img src={chef} alt="" className="mr-2" />
                                <strong>
                                    {this.props.t(
                                        'alert/holiday-notification-chef-title'
                                    )}
                                </strong>
                            </p>
                            <p>
                                {this.props.t(
                                    'alert/holiday-notification-chef'
                                )}
                            </p>
                        </>
                    ) : (
                        <p>
                            <img src={dinner} alt="" className="mr-2" />
                            {this.props.t('alert/holiday-notification')}
                        </p>
                    )}

                    <Button
                        color="primary"
                        className="no-arrow"
                        onClick={() =>
                            this.props.toggleModalWithDayReplacements()
                        }
                    >
                        {this.props.t('alert/holiday-notification/button')}
                    </Button>
                </div>
            );
        }

        return '';
    }

    renderTomorrowAlert() {
        const tomorrowAlert = [];
        if (
            this.props.dayPlanForTomorrow &&
            Object.keys(this.props.dayPlanForTomorrow).length !== 0
        ) {
            if (
                this.props.dayPlanForTomorrow.__typename ===
                'UserDayPlanSuccess'
            ) {
                this.props.dayPlanForTomorrow.events.forEach(event => {
                    if (event.__typename === 'Meal') {
                        event.dishes.forEach(dish => {
                            if (dish.recipeNoteForPreviousDay) {
                                tomorrowAlert.push(
                                    dish.recipeNoteForPreviousDay
                                );
                            }
                        });
                    }
                });
            }
        }

        const uniqTomorrowAlert = [...new Set(tomorrowAlert)];

        if (uniqTomorrowAlert.length > 0) {
            return (
                <div className="alert-new neutral" data-test="tomorrow-alert">
                    <h4>{this.props.t('next-day-notification')}</h4>
                    <ul className="list">
                        {uniqTomorrowAlert.map((alert, idx, arr) => (
                            <li key={arr.indexOf(alert)}>{alert}</li>
                        ))}
                    </ul>
                </div>
            );
        }

        return '';
    }

    renderDietPhase() {
        let dietType;
        const { dietId, isVegetarian, isPescoVegetarian, phase } = this.props;
        if (dietId === 3 || dietId === 4) {
            if (!isVegetarian) {
                dietType = 'diet-type/simple/classic';
            } else {
                dietType = 'diet-type/simple/vege';
            }
        } else if (dietId === 1 || dietId === 2) {
            if (!isVegetarian) {
                if (isPescoVegetarian) {
                    dietType = 'diet-type/vege/fish/';
                } else {
                    dietType = 'diet-type/classic/';
                }
            } else {
                dietType = 'diet-type/vege/';
            }
            dietType += phase.identifier.toLowerCase();
        } else {
            dietType = 'diet-type/instant';
        }
        return (
            <div className={`diet-phase ${phase.identifier.toLowerCase()}`}>
                {this.props.t(dietType)}
            </div>
        );
    }

    render() {
        return (
            <Layout page="diet-new">
                <div
                    className={
                        this.props.showModal ||
                        this.props.showModalWithReplacements
                            ? 'modalContainer showModal bg-grey'
                            : 'modalContainer bg-grey'
                    }
                >
                    {this.props.renderTimeline('top')}

                    <header>
                        <Container className="text-center">
                            <Row>
                                <Col md={{ size: 8, offset: 2 }}>
                                    {!this.props.print && (
                                        <React.Fragment>
                                            {this.renderGoalAlert()}
                                            {this.renderMeasurementAlert()}
                                        </React.Fragment>
                                    )}

                                    {this.renderTomorrowAlert()}

                                    <h1>
                                        {this.props.t(
                                            `meal/weekday${this.props.date.getDay()}`
                                        )}

                                        {this.props.showMacros ? (
                                            <React.Fragment>
                                                ,{' '}
                                                <span className="text-nowrap">
                                                    {this.props.print ? (
                                                        this.props.kcal
                                                    ) : (
                                                        <Odometer
                                                            value={
                                                                this.state
                                                                    .odometer
                                                            }
                                                            format="d"
                                                        />
                                                    )}{' '}
                                                    kcal
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )}
                                    </h1>

                                    {this.renderDietPhase()}

                                    {this.renderHolidayAlert()}
                                </Col>
                            </Row>
                        </Container>
                    </header>
                    {this.props.children()}
                </div>

                <CSSTransition
                    in={this.props.showModal}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-grey">
                        {this.props.renderMealLinks()}
                        {this.props.renderMealDetails()}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-grey">
                        {this.props.renderMealReplacementsList()}

                        <section className="pt-0">
                            <Container className="text-center">
                                <Row>
                                    <Col md={{ size: 8, offset: 2 }}>
                                        <Button
                                            color="primary"
                                            className="min-width"
                                            outline
                                            onClick={() => {
                                                window.history.back();
                                                callNativeAppRemoveLastRoute(
                                                    'diet'
                                                );
                                            }}
                                            type="button"
                                        >
                                            {this.props.t('cancel')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithDayReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-grey">
                        {this.props.renderChangeDay()}

                        <section className="pt-0">
                            <Container className="text-center">
                                <Row>
                                    <Col md={{ size: 8, offset: 2 }}>
                                        <Button
                                            color="primary"
                                            className="min-width"
                                            outline
                                            onClick={() => {
                                                window.history.back();
                                                callNativeAppRemoveLastRoute(
                                                    'diet'
                                                );
                                            }}
                                            type="button"
                                        >
                                            {this.props.t('cancel')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>
            </Layout>
        );
    }
}
export { DayPlan };
export default withMobileAppMode(withGTM(withLocale(DayPlan)));
